import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Section from '../components/Section'
import Container from '../components/Container'
import { withI18next, Link } from 'gatsby-plugin-i18n'

const NotFoundPage = ({ pageContext }) => {
  const { language } = pageContext
  return (
    <Layout language={language}>
      <Section>
        <Container>
          <h1>NOT FOUND</h1>

          <p>You just hit a route that doesn&apos;t exist... the sadness.</p>

          <Link to="/">Homepage</Link>
        </Container>
      </Section>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
}

export default withI18next()(NotFoundPage)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { eq: "messages" } }
    ) {
      ...LocalLocaleFragment
    }
  }
`
